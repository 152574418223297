.home-video {
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  object-fit: cover;
}
.gradient {
  height: 30rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(rgba(#121718, 0), rgba(#121718, 1));
}
