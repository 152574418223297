.footer {
  padding: 12.8rem 0;
  background-color: #121718;
  border-top: 2px solid rgba(255, 255, 255, 0.1);
  .footer-container {
    display: grid;
    max-width: 120rem;
    margin: 0 auto;
    padding: 0 3.2rem;

    grid-template-columns: 1.5fr 1fr 1fr 1.5fr auto;
    column-gap: 6.4rem;
    justify-content: center;

    .footer-column--logo {
      display: flex;
      flex-direction: column;
    }
    .footer-column--backtotop {
      display: flex;
      align-items: center;
    }

    .footer-column {
      line-height: 1.8;

      .footer-heading {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 1.8rem;
        margin-bottom: 4rem;
        line-height: 1;
        color: white;
      }
      .footer-logo {
        color: #fff;
        font-size: 2.4rem;
        text-decoration: none;
        line-height: 1;
        width: 90%;

        img {
          width: 90%;
        }
      }
      .copyright {
        margin-top: auto;
        font-size: 1.4rem;
        color: #fff;
      }
      .bold {
        font-weight: 700;
      }

      .footer-backtotop {
        width: 10rem;
        height: 9rem;
        cursor: pointer;
        transition: all 0.4s ease-in;

        &:hover,
        &:active {
          opacity: 1;
        }
      }

      .footer-info {
        color: white;
        text-decoration: none;
        font-size: 1.6rem;
        font-weight: 500;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          height: 1px;
          background: #fff;
          bottom: 0;
          width: 0;
          left: auto;
          right: 0;
          -webkit-transition: all 0.3s ease-out;
          -moz-transition: all 0.3s ease-out;
          -o-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }

        &:hover:after {
          width: 100%;
          left: 0;
          right: auto;
          -webkit-transition: all 0.3s ease-out;
          -moz-transition: all 0.3s ease-out;
          -o-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }
      }

      .footer-address {
        text-decoration: none;
        color: #fff;
        font-weight: 500;
      }

      .footer-address-container {
        width: fit-content;
      }
    }
  }
  @media (max-width: 44em) {
    .footer-container {
      margin-right: auto;
      grid-template-columns: auto auto auto;
      row-gap: 4.8rem;
      column-gap: 1.8rem;
      align-items: right;
      justify-content: end;

      .footer-column {
        .footer-info {
          font-size: 1.2rem;
        }
        .copyright {
          margin-top: 3.6rem;
          font-size: 1.2rem;
        }
      }

      .footer-column--logo {
        grid-column: 1/3;
        grid-row: 1;
      }
      .footer-column--backtotop {
        grid-column: 3;
        grid-row: 1;
      }
    }
  }
}
