.contact-box {
  padding: calc(9.6rem + 10.8rem) 2.4rem 9.6rem;
  margin: 0 auto;
  background: #121718;
  text-align: center;
  position: relative;

  h2 {
    color: white;
    font-size: 5.2rem;
    margin-bottom: 8rem;
  }

  .contact-box-container {
    margin: 0 auto;
    max-width: 120rem;
    border: 2px solid #3b5bdb;
    box-shadow: 0px 0px 40px 10px #3b5bdb;
    background-color: #121516;
    border-radius: 8px;
    display: grid;
    grid-template-columns: auto 1fr;

    .contact-box-direct {
      padding: 4.8rem;
      background-color: rgba(80, 109, 223, 0.75);
      border-right: 2px solid #3b5bdb;
      //   box-shadow: 0px 0px 40px 10px #3b5bdb;
      color: white;
      h3 {
        font-size: 3rem;
        margin-bottom: 4rem;
      }

      ion-icon {
        color: white;
        font-size: 4.8rem;
        margin-right: 1.8rem;
      }

      .contact-direct {
        display: flex;
        align-items: center;
        margin-bottom: 2.4rem;
      }
      .contact-link {
        position: relative;
        line-height: 1.8rem;
        font-size: 1.8rem;
        color: white;
        text-decoration: none;
        font-weight: 500;
        text-align: left;
        &::after {
          content: '';
          position: absolute;
          height: 1px;
          background: #fff;
          bottom: 0;
          width: 0;
          left: auto;
          right: 0;
          -webkit-transition: all 0.3s ease-out;
          -moz-transition: all 0.3s ease-out;
          -o-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }

        &:hover:after {
          width: 100%;
          left: 0;
          right: auto;
          -webkit-transition: all 0.3s ease-out;
          -moz-transition: all 0.3s ease-out;
          -o-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }
      }
      .contact-address {
        line-height: 1.5;
      }
      .contact-hours {
        text-align: left;
        line-height: 1.6;
        h4 {
          text-transform: uppercase;
          font-size: 2.4rem;
        }

        .contact-hour {
          display: flex;
          p:last-child {
            margin-left: auto;
            font-weight: 500;
          }
        }
      }
    }
  }
  @media (max-width: 44em) {
    h2 {
      font-size: 4.4rem;
    }
    .contact-box-container {
      grid-template-columns: auto;
      .contact-box-direct {
        .contact-link {
          font-size: 1.6rem;
        }
      }
    }
  }
  @media (max-width: 34em) {
    h2 {
      font-size: 3.6rem;
    }
    .contact-box-container {
      grid-template-columns: auto;
      .contact-box-direct {
        h3 {
          font-size: 2.4rem;
        }
        .contact-link {
          font-size: 1.4rem;
        }
      }
    }
  }
}
