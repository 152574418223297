.hero {
  height: 100vh;
  display: flex;
  justify-content: center;

  .hero-container {
    max-width: 80rem;
    padding: 0 2.4rem;
    color: white;
    position: absolute;
    text-align: left;
    bottom: 50%;
    transform: translate(0, 50%);

    .hero-textbox {
      img {
        width: 100%;
        margin-bottom: 3.2rem;
      }
      p {
        font-size: 1.8rem;
        line-height: 1.6;
      }
    }

    .hero-buttons {
      margin-top: 4.8rem;
    }
  }
  .gradient {
    height: 30rem;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: -1;
    background: linear-gradient(rgba(#121718, 0), rgba(#121718, 1));
  }

  .btn {
    font-size: 1.8rem;
    color: white;
    text-decoration: none;
    font-weight: 500;
    display: inline-block;
    padding: 1.8rem 3.2rem;
    box-shadow: 3px 12px 24px rgba(#000, 0.5);
    border-radius: 8px;
    transition: all 0.4s;
  }

  .btn:hover,
  .btn:active {
    box-shadow: 1px 4px 12px rgba(#000, 0.5);
  }

  .btn--contact {
    background-color: #3b5bdb;
    margin-right: 1.8rem;
  }

  .btn--contact:active,
  .btn--contact:hover {
    background-color: #364fc7;
  }
}
@supports (-webkit-touch-callout: none) {
  .hero {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}
