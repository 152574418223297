.service-maintenance-container {
  padding-top: 9.6rem;
  max-width: 120rem;
  margin: 0 auto;
  color: #121718;

  .service-maintenance-finish {
    line-height: 1.6;
    font-size: 2.4rem;
    text-align: center;

    a {
      text-decoration: none;
      color: #121718;
      font-weight: 600;
    }
  }
  .service-maintenance-cards {
    display: flex;
    flex-direction: column;

    ion-icon {
      font-size: 16rem;
      text-align: center;
      align-self: center;
    }

    .card {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 9.6rem;
      //   gap: 4.8rem;
      margin-bottom: 4.8rem;
      .card-content {
        width: 50%;

        h2 {
          font-size: 3.6rem;
          margin-bottom: 1.6rem;
        }
        ul {
          text-align: left;
          line-height: 1.6;
          font-size: 1.8rem;
          margin-left: 1.8rem;
        }
        p {
          line-height: 1.6;
          font-size: 1.8rem;
          margin-bottom: 1.2rem;
        }
      }
    }
  }
  .service-support-cards {
    margin-top: 2.4rem;
    margin-bottom: 12.8rem;
    display: flex;
    justify-content: center;
    gap: 8rem;
    .card {
      width: 40%;
      text-align: center;
      ion-icon {
        font-size: 12.6rem;
      }
      h2 {
        font-size: 3.6rem;
        margin-bottom: 1.6rem;
      }
      p {
        text-align: left;
        line-height: 1.6;
        font-size: 1.8rem;
      }
    }
  }
  @media (max-width: 44em) {
    .service-maintenance-cards {
      ion-icon {
        font-size: 14rem;
      }
      .card {
        gap: 8rem;
        .card-content {
          h2 {
            font-size: 3rem;
            margin-bottom: 1.6rem;
          }
          ul {
            text-align: left;
            line-height: 1.6;
            font-size: 1.6rem;
            margin-left: 1.8rem;
          }
          p {
            line-height: 1.6;
            font-size: 1.6rem;
            margin-bottom: 1.2rem;
          }
        }
      }
    }
    .service-support-cards {
      gap: 6.4rem;
      .card {
        ion-icon {
          font-size: 9.6rem;
        }
        h2 {
          font-size: 3rem;
        }
        p {
          font-size: 1.6rem;
        }
      }
    }
  }
  @media (max-width: 34em) {
    .service-maintenance-cards {
      ion-icon {
        font-size: 10rem;
      }
      .card {
        gap: 2.4rem;
        .card-content {
          h2 {
            font-size: 2.2rem;
            margin-bottom: 1.6rem;
          }
          ul {
            text-align: left;
            line-height: 1.6;
            font-size: 1.4rem;
            margin-left: 1.8rem;
          }
          p {
            line-height: 1.6;
            font-size: 1.4rem;
            margin-bottom: 1.2rem;
          }
        }
      }
    }
    .service-support-cards {
      gap: 6.4rem;
      .card {
        ion-icon {
          font-size: 8rem;
        }
        h2 {
          font-size: 2rem;
        }
        p {
          font-size: 1.4rem;
        }
      }
    }
  }
}
