.contact-form {
  color: white;
  padding: 9.6rem;
  width: 100%;
  .contact-form-container {
    .form {
      display: grid;
      grid-template-rows: auto auto auto;
      gap: 2.4rem;
      .form-submit {
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        display: inline-block;
        font-size: 1.8rem;
        border: 0;
        background-color: #343a40;
        padding: 1.8rem 3.2rem;
        box-shadow: 3px 12px 24px rgb(33 37 41 / 50%);
        border-radius: 8px;
        transition: all 0.4s;
        color: #868e96;
        margin: 0 auto;

        &.valid {
          background-color: #3b5bdb;
          color: white;
        }

        &.valid:active,
        &.valid:hover {
          background-color: #364fc7;
          box-shadow: 1px 4px 12px rgba(#212529, 0.5);
        }
      }

      .user-details {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
      }
      .user-info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2.4rem;
      }
      .input-container {
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
        align-items: flex-start;
        position: relative;

        input.invalid {
          border-bottom: 2px solid #c92a2a;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:focus {
          transition: background-color 600000s 0s, color 600000s 0s;
        }
        input[data-autocompleted] {
          background-color: transparent !important;
        }

        .placeholder {
          color: #3b5bdb;
        }

        &::after {
          content: '';
          position: absolute;
          display: block;
          bottom: 0;
          width: 0;
          height: 2px;
          background-color: #3b5bdb;

          transition: all 0.3s ease-in-out;
        }

        &:active::after,
        &:hover::after {
          width: 100%;
        }

        label {
          font-size: 1.8rem;
          font-weight: 500;
        }

        input {
          display: block;
          font-size: 1.8rem;
          width: 100%;
          padding: 8px 5px;
          border: 0;
          outline: none;
          font-family: 'Montserrat', sans-serif;
          border-bottom: 2px solid white;
          background-color: #121516;
          color: white;
        }

        input:focus {
          border-bottom: 2px solid #3b5bdb;
          transition: all 0.5s ease-in-out;
        }

        textarea:focus {
          border-bottom: 2px solid #3b5bdb;
          transition: all 0.5s ease-in-out;
        }

        select:focus {
          border-bottom: 2px solid #3b5bdb;
          transition: all 0.5s ease-in-out;
        }

        select {
          font-size: 1.8rem;
          padding: 0.8rem 0;
          outline: none;
          border: 0;
          background-color: #121516;
          color: rgb(118, 118, 118);
          border-bottom: 2px solid white;
          font-family: 'Montserrat', sans-serif;
          width: 100%;
        }

        select.change {
          color: white;
          transition: all 0s;
        }

        optgroup {
          color: white;
        }

        textarea {
          font-size: 1.8rem;
          font-family: 'Montserrat', sans-serif;
          resize: none;
          padding: 0.8rem 0.5rem;
          width: 100%;
          border: 0;
          outline: none;
          border-bottom: 2px solid white;
          background-color: #121516;
          color: white;
        }
      }
    }
  }
  @media (max-width: 44em) {
    & {
      grid-row: 1;
    }
  }
  @media (max-width: 34em) {
    & {
      padding: 4.8rem;
    }
    .contact-form-container {
      .form {
        .user-info {
          .input-container {
            label {
              font-size: 1.6rem;
            }
            input {
              font-size: 1.6rem;
            }
          }
        }
        .user-details {
          .input-container {
            label {
              font-size: 1.6rem;
            }
            select {
              font-size: 1.6rem;
            }
            textarea {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
}
