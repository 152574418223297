.navigation {
  z-index: 99;
  width: 100%;
  height: 10.8rem;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid transparent;
  position: absolute;
  transition: all 0.5s ease-in;
  &.scroll {
    position: fixed;
    background-color: rgba(18, 23, 24, 0.9);
    border-color: rgba(255, 255, 255, 0.1);
  }
  .btn-mobile-nav {
    border: none;
    background: none;
    cursor: pointer;

    display: none;
  }
  .icon-mobile-nav {
    height: 4.8rem;
    width: 4.8rem;
    color: #fff;
  }
  .icon-mobile-nav[name='close-outline'] {
    display: none;
  }
  .navigation-container {
    display: flex;
    align-items: center;
    width: 120rem;
    padding: 0 2.4rem;
  }

  .navigation-logo {
    max-width: 50%;
    align-self: end;
    text-decoration: none;
    color: white;
    margin-right: auto;
    font-size: 4rem;
    font-weight: 300;
    height: 90%;
    .logo {
      height: 8rem;
      // width: 10rem;
    }
  }
  .bold {
    font-weight: 700;
  }
  .navigation-links {
    display: flex;
    align-items: center;
    gap: 3.6rem;
    .navigation-link {
      color: white;
      text-decoration: none;
      font-size: 1.8rem;
      font-weight: 500;
    }
  }

  .contact {
    padding: 1.8rem 3.2rem;
    background-color: #3b5bdb;
    box-shadow: 3px 12px 24px rgba(#212529, 0.5);
    border-radius: 8px;
    transition: all 0.4s;
  }

  .contact:active,
  .contact:hover {
    background-color: #364fc7;
    box-shadow: 1px 4px 12px rgba(#212529, 0.5);
  }

  @media (max-width: 44em) {
    .navigation-container {
      max-width: 100%;
    }
    .btn-mobile-nav {
      display: block;
      z-index: 9999;
    }
    .open .icon-mobile-nav[name='close-outline'] {
      display: block;
    }

    .open .icon-mobile-nav[name='menu-outline'] {
      display: none;
    }
    .navigation-links {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      background-color: rgba(0, 0, 0, 0.7);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(10px);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      transform: translateX(100%);

      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease-in;
      flex-direction: column;
      gap: 4.8rem;
    }

    .navigation-link {
      font-size: 3rem;
    }

    .open .navigation-links {
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
      transform: translateX(0);
    }
  }
}
