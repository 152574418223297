.services-content {
  margin-top: 9.6rem;
  padding: 0 2.4rem;
  display: flex;
  flex-direction: column;
  .services-title {
    margin: 0 auto;
    h1 {
      text-align: center;
      font-size: 4.4rem;
      font-weight: 700;
      margin-bottom: 6.4rem;
    }
    .tabs {
      max-width: 60rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      border-radius: 99px;
      box-shadow: 3px 6px 12px rgba($color: #000000, $alpha: 0.3);
      cursor: pointer;

      p {
        text-align: center;
        width: 33%;
        padding: 3.6rem;
        display: inline-block;
        font-weight: 700;
        font-size: 1.8rem;
        border-radius: 99px;
        transition: all 0.4s ease-in-out;
      }

      p:hover {
        box-shadow: 2px 2px 24px rgba($color: #000000, $alpha: 0.1);
      }

      p.active {
        background-color: #121718;
        // background-color: #3b5bdb;
        box-shadow: 0px 0px 20px 3px #121718;
        // box-shadow: 0px 0px 20px 3px #3b5bdb;
        color: white;
      }
    }
  }
  @media (max-width: 44em) {
    .services-title {
      h1 {
        font-size: 3.6rem;
      }
      .tabs {
        p {
          font-size: 1.6rem;
        }
      }
    }
  }
  @media (max-width: 34em) {
    .services-title {
      h1 {
        font-size: 3rem;
      }
      .tabs {
        // max-width: 90%;
        p {
          font-size: 1.2rem;
          padding: 2.4rem;
        }
      }
    }
  }
}
