.service-installations-container {
  padding-top: 9.6rem;
  max-width: 120rem;
  margin: 0 auto;

  .service-maintenance-finish {
    line-height: 1.6;
    font-size: 2.4rem;
    text-align: center;

    a {
      text-decoration: none;
      color: #121718;
      font-weight: 600;
    }
  }
  .installations {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4.8rem;
    margin-bottom: 12.8rem;

    .aircraft-logos {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(4, 12rem);
      justify-content: center;
      align-items: center;
      gap: 2.4rem;
      img {
        width: 100%;
        max-height: max-content;
        filter: brightness(0);
        opacity: 50%;
      }
    }

    .aircraft-brands {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(4, 12rem);
      justify-content: center;
      align-items: center;
      gap: 2.4rem;
      img {
        width: 100%;
        max-height: max-content;
        filter: brightness(0);
        opacity: 50%;
      }
    }

    .install-card {
      text-align: center;
      ion-icon {
        font-size: 9.6rem;
        margin-bottom: 1.6rem;
      }
      h2 {
        font-size: 3.6rem;
        margin-bottom: 1.6rem;
      }

      p {
        text-align: left;
        line-height: 1.6;
        font-size: 1.8rem;
        margin-bottom: 1.2rem;
      }
      ul {
        text-align: left;
        line-height: 1.6;
        font-size: 1.8rem;
        margin-left: 1.8rem;
      }
    }

    .working-on-card {
      align-self: center;
      text-align: center;
      ion-icon {
        font-size: 9.6rem;
        margin-bottom: 1.6rem;
      }
      h2 {
        font-size: 3.6rem;
        margin-bottom: 1.6rem;
      }
      p {
        text-align: left;
        line-height: 1.6;
        font-size: 1.8rem;
        margin-bottom: 1.2rem;
      }
    }
  }
  @media (max-width: 44em) {
    .installations {
      grid-template-columns: 1fr;
      row-gap: 4.8rem;
      .aircraft-logos {
        grid-row: 2;
      }
      .install-card {
        padding: 0 2.4rem;
      }
      .working-on-card {
        padding: 0 2.4rem;
      }
    }
  }
}
