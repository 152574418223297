.home-services {
  padding: 9.6rem 0 0;
  background-color: #121718;
  color: #fff;
  position: relative;
  .services-container {
    max-width: 120rem;
    padding: 0 3.2rem;
    margin: 0 auto;

    .service-textbox {
      .subheading {
        text-transform: uppercase;
        font-size: 1.6rem;
        display: inline-block;
        padding-bottom: 1.6rem;
        color: #3b5bdb;
        font-weight: 700;
      }
      h1 {
        font-size: 4.4rem;
        line-height: 1.2;
        margin-bottom: 9.6rem;
      }
    }
  }
  .service-feature-boxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3.6rem;
    .service-box {
      display: flex;
      flex-direction: column;
      border: 2px solid #17214b;
      border-radius: 8px;
      background-color: #121516;
      padding: 4.6rem;
      transition: all 0.4s;

      ion-icon {
        font-size: 9.6rem;
        margin-bottom: 2.4rem;
      }

      h2 {
        font-size: 3.6rem;
        margin-bottom: 1.6rem;
      }

      p {
        line-height: 1.6;
        margin-bottom: 3.6rem;
      }

      &:hover {
        transform: translate(0, -1%);
      }
    }
  }
  .btn {
    font-size: 1.8rem;
    color: white;
    text-decoration: none;
    font-weight: 500;
    background-color: #3b5bdb;
    display: inline-block;
    padding: 1.8rem 3.2rem;
    box-shadow: 3px 12px 24px rgba(#000, 0.5);
    border-radius: 8px;
    transition: all 0.4s;
    margin: auto auto 0;
    width: max-content;
  }

  .btn:hover,
  .btn:active {
    background-color: #364fc7;
    box-shadow: 1px 4px 12px rgba(#000, 0.5);
  }

  .curve {
    transform: translate(0, 1.3%);
  }
  .about-trusted {
    max-width: 120rem;
    grid-column: 1/-1;
    margin: 9.6rem auto 0;
    text-align: center;
    .about-trusted-text {
      font-size: 1.8rem;
      line-height: 1.6;
      font-weight: 500;
      margin-bottom: 2.4rem;
    }

    .about-trusted-logos {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .trusted-logo {
        height: 4rem;
        max-width: max-content;
        filter: brightness(100);
      }
    }
  }
  @media (max-width: 59em) {
    .curve {
      transform: translate(0, 2%);
    }
    .service-feature-boxes {
      .service-box {
        ion-icon {
          font-size: 7.2rem;
        }

        h2 {
          font-size: 2.4rem;
        }
      }
    }
    .btn {
      font-size: 1.5rem;
    }
    .about-trusted {
      .about-trusted-text {
        font-size: 1.4rem;
      }

      .about-trusted-logos {
        .trusted-logo {
          height: 3.4rem;
        }
      }
    }
  }
  @media (max-width: 44em) {
    .curve {
      transform: translate(0, 3%);
    }
    .service-feature-boxes {
      grid-template-columns: 1fr;
      .service-box {
        ion-icon {
          margin: 0 auto 2.4rem;
          font-size: 9.6rem;
        }

        h2 {
          text-align: center;
          font-size: 3.6rem;
        }
      }
    }
    .about-trusted {
      .about-trusted-text {
        font-size: 1.6rem;
      }

      .about-trusted-logos {
        flex-direction: column;
        gap: 3.6rem;
        .trusted-logo {
          height: 5rem;
        }
      }
    }
    .services-container {
      .service-textbox {
        h1 {
          font-size: 4rem;
        }
      }
    }
  }
  @media (max-width: 34em) {
    .curve {
      transform: translate(0, 4.3%);
    }
    .about-trusted {
      .about-trusted-text {
        font-size: 1.4rem;
        padding: 0 4rem;
      }

      .about-trusted-logos {
        gap: 3.2rem;
        .trusted-logo {
          height: 3.6rem;
        }
      }
    }
    .services-container {
      .service-textbox {
        h1 {
          font-size: 3.6rem;
        }
      }
    }
  }
}
