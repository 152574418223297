.home-about {
  background-color: #e9ecef;
  padding: 9.6rem 0 0;
  color: #121718;
  height: 150%;

  .about-container {
    max-width: 120rem;
    margin: 0 auto;
    align-items: center;
    column-gap: 3.2rem;
    // row-gap: 9.6rem;

    .about-textbox {
      text-align: center;
      margin-bottom: 4.8rem;
      .subheading {
        text-transform: uppercase;
        font-size: 1.6rem;
        display: inline-block;
        padding-bottom: 1.6rem;
        color: #3b5bdb;
        font-weight: 700;
      }
      h1 {
        font-size: 4.4rem;
        line-height: 1.2;
        padding: 0 2.4rem;
      }
    }

    .about-content-textbox {
      padding: 0 1.6rem;
      margin: auto 0;
      h3 {
        position: relative;
        line-height: 1.15;
        font-size: 3.2rem;
        font-weight: 700;
        margin-bottom: 2.4rem;
        color: #121718;
      }

      p {
        position: relative;
        line-height: 2;
        z-index: 1;
      }
    }
    .about-block {
      display: flex;
      margin-bottom: 4.8rem;
      .about-content-textbox {
        width: 75%;
      }
    }
    .about-block:last-child {
      margin-bottom: 0;
    }

    .about-content-image {
      padding: 0 1.6rem;
      display: flex;
      img {
        width: 100%;
        border-radius: 3px;
        transition: all 0.4s;
      }
    }
  }
  @media (max-width: 44em) {
    .about-textbox {
      h1 {
        font-size: 4rem;
      }
      .about-content-textbox {
        h3 {
          font-size: 3.2rem;
        }
      }
      .image--2 {
        grid-row: 5;
      }
    }
  }
  @media (max-width: 34em) {
    .about-block {
      flex-direction: column;
      align-items: center;
      // text-align: center;
      .about-content-image {
        width: 75%;
        margin: 1.6rem auto 0;
        padding: 0;
      }
    }
    .about-block--2 {
      flex-direction: column-reverse;
    }
    .about-textbox {
      h1 {
        font-size: 3.6rem;
      }
    }
  }
}
