.about-content {
  p {
    line-height: 1.6;
  }
  margin: 9.6rem 0 0;
  .about-content-container {
    position: relative;
    max-width: 120rem;
    padding: 0 2.4rem;
    margin: 0 auto;

    .about-content-block {
      max-width: 80rem;
      margin: 0 auto;
      margin-bottom: 8rem;
      .about-title {
        font-size: 4.4rem;
        text-align: center;
        margin-bottom: 1.6rem;
      }
      .about-text {
        font-size: 1.8rem;
      }
    }

    .about-content-goals {
      display: flex;
      justify-content: space-between;
      margin-bottom: 9.6rem;

      .about-content-goal {
        width: 45%;
        .about-title {
          margin-bottom: 1.6rem;
          text-align: center;
          font-size: 3.6rem;
        }
        .about-text {
          font-size: 1.8rem;
        }
      }
    }
  }
  .about-content-info {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: 40rem;
    max-width: 80vw;
    background-color: #3b5bdb;
    clip-path: polygon(0 0, 100% 0%, calc(100% - 100px) 100%, 0% 100%);
    position: relative;
    z-index: 10;
    .about-content-text {
      width: 70%;
      line-height: 1.8;
      padding: 0 12.8rem 0 6.4rem;
      font-size: 2.2rem;
      width: 90%;
    }

    .years {
      font-size: 40rem;
      font-weight: 700;
      opacity: 0.3;
      position: absolute;
      right: 0;
    }
  }

  .about-content-info-wrap {
    filter: drop-shadow(6px 6px 12px rgba(19, 15, 19, 0.5));
  }
  @media (max-width: 44em) {
    .about-content-container {
      .about-content-block {
        .about-title {
          font-size: 3.6rem;
        }
        .about-text {
          font-size: 1.6rem;
        }
      }

      .about-content-goals {
        .about-content-goal {
          .about-title {
            font-size: 3rem;
          }
          .about-text {
            font-size: 1.6rem;
          }
        }
      }
    }
    .about-content-info {
      max-width: 90vw;
      .about-content-text {
        font-size: 1.8rem;
        padding: 0 9.6rem 0 3.2rem;
      }
    }
  }
  @media (max-width: 34em) {
    .about-content-container {
      .about-content-block {
        .about-title {
          font-size: 3rem;
        }
        .about-text {
          font-size: 1.4rem;
        }
      }

      .about-content-goals {
        .about-content-goal {
          .about-title {
            font-size: 2.4rem;
          }
          .about-text {
            font-size: 1.4rem;
          }
        }
      }
    }
    .about-content-info {
      .about-content-text {
        font-size: 1.4rem;
        padding: 0 8rem 0 2.4rem;
      }
    }
  }
}
