.about-contact-container {
  margin: 0 auto;
  max-width: 100rem;
  padding: 12.8rem 6.2rem;
  display: flex;
  align-items: center;
  color: #121718;

  .home-contact-textbox {
    width: 70%;
    h2 {
      font-size: 5.2rem;
      margin-bottom: 2.4rem;
      width: 80%;
    }
    p {
      font-size: 1.8rem;
      line-height: 1.6;
      strong {
        font-weight: 600;
      }
    }
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20rem;
    width: 20rem;
    background-color: #3b5bdb;
    box-shadow: 0px 0px 20px 10px #3b5bdb;
    border-radius: 50%;
    color: white;
    font-weight: 500;
    font-size: 2.2rem;
    text-decoration: none;
    margin: 0 0 0 auto;
    transition: all 0.4s;
  }

  .btn:active,
  .btn:hover {
    background-color: #364fc7;
    box-shadow: 0px 0px 7px 2px #3b5bdb;
  }
  @media (max-width: 44em) {
    & {
      flex-direction: column;
      padding: 12.8rem 6.2rem 6.4rem;
    }
    .btn {
      margin: 6.4rem auto;
    }
    .home-contact-textbox {
      h2 {
        font-size: 4.4rem;
      }
      p {
        font-size: 1.6rem;
      }
    }
  }
  @media (max-width: 44em) {
    .home-contact-textbox {
      // text-align: center;
      h2 {
        font-size: 3.4rem;
      }
      p {
        font-size: 1.4rem;
      }
    }
  }
}
