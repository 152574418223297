.home-contact {
  background-color: #e9ecef;
  padding: 0 2.4rem 12.8rem;
  position: relative;

  .home-contact-container {
    box-shadow: 6px 12px 24px rgba(#212529, 0.75);
    margin: 0 auto;
    max-width: 100rem;
    padding: 6.2rem;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    background-color: #121718;
    color: white;
    transition: all 0.3s;

    h1 {
      font-size: 6.2rem;
      margin-bottom: 2.4rem;
    }
    &:hover {
      box-shadow: 3px 6px 12px rgba(#212529, 0.75);
      transform: translate(0, -0.33%);
    }

    .home-contact-textbox {
      text-align: center;
      p {
        font-size: 1.8rem;
        margin-bottom: 3.2rem;
      }
    }

    .btn {
      display: inline-block;
      padding: 1.8rem 3.2rem;
      background-color: #3b5bdb;
      box-shadow: 0px 0px 10px 7px #243474;
      border-radius: 8px;
      color: white;
      font-weight: 500;
      font-size: 2.4rem;
      text-decoration: none;
      margin: 0 auto;
      transition: all 0.4s;
    }

    .btn:active,
    .btn:hover {
      background-color: #364fc7;
      box-shadow: 0px 0px 7px 3px #243474;
    }

    .contact-direct {
      display: flex;
      justify-content: center;
      margin-top: 4.8rem;
      gap: 4.8rem;
      a {
        color: white;
        width: 100%;
        text-decoration: none;
        display: inline-block;
      }
      .contact-box {
        padding: 4.6rem;
        border: 2px solid #17214b;
        background-color: #121516;
        border-radius: 8px;
        text-align: left;
        transition: all 0.3s;

        .call-hours {
          display: flex;
        }
      }

      .contact-box:hover,
      .contact-box:active {
        transform: translate(0, -1%);
      }

      ion-icon {
        color: white;
        font-size: 5.6rem;
        margin-bottom: 4.8rem;
      }

      .title {
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 1.2rem;
      }

      .hours {
        font-size: 1.4rem;
        color: #3b5bdb;
        font-weight: 700;
        margin-left: auto;
      }

      .info {
        font-size: 1.8rem;
      }
    }
  }
  @media (max-width: 59em) {
    .contact-direct {
      flex-direction: column-reverse;
    }
  }

  @media (max-width: 44em) {
    .home-contact-textbox {
      .home-contact-textbox-title {
        font-size: 4.8rem;
      }
    }
  }
  @media (max-width: 34em) {
    .home-contact-container {
      padding: 3.2rem;
    }
    .home-contact-textbox {
      .home-contact-textbox-title {
        font-size: 3.6rem;
      }
    }
    .home-contact-container {
      .contact-direct {
        gap: 2.4rem;
        .contact-box {
          .call-hours {
            flex-direction: column;
            .title {
              margin-bottom: 0;
            }
            .hours {
              margin: 0.5rem 0 1rem;
            }
          }
        }
      }
    }
  }
}
