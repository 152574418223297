.services-header {
  position: relative;
  height: 100vh;
  background-size: cover;
  color: #fff;
  z-index: 1;
  display: flex;
  justify-content: center;

  .services-header-textbox {
    max-width: 80rem;
    padding: 0 2.4rem;
    color: white;
    position: absolute;
    text-align: left;
    bottom: 33%;
    transform: translate(0, 50%);

    h1 {
      font-size: 6.4rem;
      margin-bottom: 3.2rem;
    }

    p {
      line-height: 1.6;
      font-size: 1.8rem;
    }
  }
  .curve {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translate(0, 1.3%);
  }
  @media (max-width: 59em) {
    .curve {
      transform: translate(0, 2.2%);
    }
  }
  @media (max-width: 44em) {
    .curve {
      transform: translate(0, 3%);
    }
    .services-header-textbox {
      h1 {
        font-size: 5.6rem;
      }
      p {
        font-size: 1.6rem;
      }
    }
  }
  @media (max-width: 34em) {
    .curve {
      transform: translate(0, 4.2%);
    }
    .services-header-textbox {
      h1 {
        font-size: 4.8rem;
      }
      p {
        font-size: 1.4rem;
      }
    }
  }
}
