.service-sales-container {
  padding-top: 9.6rem;
  max-width: 120rem;
  margin: 0 auto;

  .parts-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3.6rem;
    align-items: center;

    text-align: center;
    ion-icon {
      font-size: 9.6rem;
      margin-bottom: 1.6rem;
    }
    h2 {
      font-size: 3.6rem;
      margin-bottom: 1.6rem;
    }

    p {
      text-align: left;
      line-height: 1.6;
      font-size: 1.8rem;
      margin-bottom: 1.2rem;
    }

    .aircraft-brands {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: repeat(4, 12rem);
      justify-content: center;
      align-items: center;
      gap: 2.4rem;
      img {
        width: 100%;
        max-height: max-content;
        filter: brightness(0);
        opacity: 50%;
      }
    }
  }
  .part-partnership {
    margin: 9.6rem auto 12.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80rem;
    .part-partnership-logos {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3.6rem;
      margin-bottom: 1.6rem;
      img {
        height: 10rem;
      }
      span {
        font-size: 3.6rem;
        font-weight: 700;
      }
    }
    p {
      text-align: center;
      line-height: 1.6;
      font-size: 2rem;
      max-width: 80rem;
    }
    .partner {
      font-size: 1.6rem;
      font-weight: 700;
      margin-bottom: 1.2rem;
      text-transform: uppercase;
      color: #3b5bdb;

      .trademark {
        font-size: 0.8rem;
      }
    }
    a {
      margin-top: 2.4rem;
      text-decoration: none;
      font-size: 2.4rem;
      font-weight: 700;
      color: #fff;
      background-color: #3b5bdb;
      padding: 1.8rem 2.4rem;
      border-radius: 99px;
      box-shadow: 3px 6px 12px rgba($color: #000000, $alpha: 0.3);
      transition: all 0.4s;
    }
    a:hover {
      box-shadow: 1.5px 3px 12px rgba($color: #000000, $alpha: 0.3);
      background-color: #364fc7;
    }
  }

  .service-maintenance-finish {
    line-height: 1.6;
    font-size: 2.4rem;
    text-align: center;

    a {
      text-decoration: none;
      color: #121718;
      font-weight: 600;
    }
  }
  @media (max-width: 44em) {
    .parts-container {
      grid-template-columns: 1fr;
      h2 {
        font-size: 3rem;
      }

      p {
        font-size: 1.6rem;
      }
      .part-sales {
        grid-row: 1;
      }
    }
    .part-partnership {
      .part-partnership-logos {
        img {
          height: 7rem;
        }
      }
      p {
        font-size: 1.6rem;
      }
    }
  }
  @media (max-width: 34em) {
    .parts-container {
      h2 {
        font-size: 2.4rem;
      }

      p {
        font-size: 1.4rem;
      }
    }
    .part-partnership {
      .part-partnership-logos {
        img {
          height: 3.5rem;
        }
      }
      p {
        font-size: 1.4rem;
      }
    }
  }
}
